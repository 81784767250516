@import 'assets/scss/vars';

.sticker {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  padding: 7px 8px;
  text-align: center;
  white-space: nowrap;
  background: #e6e6e6;

  &:hover {
    color: #FFF;
    text-decoration: underline; }

  &.sticker--large {
    font-size: 18px;
    padding: 11px 20px; } }

.sticker--yellow {
  background: #ffcf5c; }

.sticker--blue {
  background: #6979f8; }

.sticker--pink {
  background: $accent; }

.sticker--purple {
  background: $accent; }
