@import '../vars';
@import "~bootstrap/scss/bootstrap-grid";
@import '../_mixins/stickers';

.primary-header {
  @include media-breakpoint-down(md) {
    margin-bottom: 36px;
    font-size: 30px; } }

.slider-wrap.container {
  box-sizing: border-box; }

/* === Section popular === */
.popular {
  margin-top: 30px;
  padding-bottom: 50px;
  @include media-breakpoint-down(md) {
    margin-top: 0; } }

.popular__desc {
  font-size: 18px;
  margin-bottom: 32px; }

.popular__row {
  &:not(:last-child) {
    margin-bottom: 50px; } }

.slider-container {
  .owl-nav {
    position: absolute;
    top: calc(50% - 30px);
    width: 100%; } }

.slider-container {
  .owl-prev {
    position: relative;
    left: -25px; } }

.slider-container {
  .owl-next {
    position: absolute;
    right: -25px; } }

.slider-container {
  .col-slider {
    max-width: 100%;
    margin: 0 auto; } }

.popular-item {
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  transition: box-shadow .4s ease;
  border: 1px solid rgba(0, 0, 0, .1);
  position: relative;
  box-sizing: border-box;
  a {
    position: relative;
    display: block; }

  &:hover {
    box-shadow: 0 5px 8px rgba(0, 0, 0, .1); } }

.popular-item__cover {
  height: 210px;
  position: relative;
  .img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .lds-default {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); } }

.popular-item__info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 197px;
  padding: 14px 21px 21px;
  justify-content: space-between; }

.popular-item__name {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 4px;
  max-height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }

.popular-item__author {
  display: flex;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis; }

.popular-item__btn {
  line-height: 1;
  margin-top: 15px;

  &:hover {
    text-decoration: none; }

  &:focus {
    text-decoration: none; } }

.popular__list {
  margin-bottom: 20px;
  margin-top: 30px; }

.popular__action {
  text-align: center;
  margin-top: 30px; }

.popular-item__sticker {
  bottom: 0;
  left: 0;
  position: absolute;
  display: inline-block;
  background: #e6e6e6;
  color: #464646; }

/* === Section our features === */
.our-features {
  margin-bottom: 55px;
  margin-top: 27px; }

.our-features__header {
  margin-bottom: 16px;
  text-align: left;
  @include media-breakpoint-down(md) {
    text-align: center;
    font-size: 30px; } }

.our-features__desc {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 24px;
  @include media-breakpoint-down(md) {
    font-size: 20px; } }

.our-features__img {
  @include media-breakpoint-down(md) {
    display: none; } }

.our-features {
  .slide-col {
    width: 50%;
    padding: 0 15px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      width: 100%;
      &:last-of-type {
        display: none; } } }
  .our-features__item {
    display: flex;
    flex-direction: row;
    height: 100%;
    .img {
      height: 100%;
      width: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      min-height: 300px; } } }

.our-features__img {
  height: 100%;
  width: 100%; }

.our-features__item.swiper-slide {
  height: 100%; }
.our-features .lds-default {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%); }

/* === Section subscribe newsletter ===*/
.newsletter {
  padding-bottom: 96px;
  padding-top: 20px; }

.newsletter-form {
  text-align: center; }

.newsletter-form__header {
  font-family: $accent-font;
  font-size: 24px;
  margin-bottom: 34px; }

.newsletter-form__group {
  display: flex;
  margin: 0 auto;
  max-width: 524px;
  width: 100%; }

.newsletter-form__field {
  border: none;
  border-radius: 6px 0 0 6px;
  padding: 11px 16px;
  width: 50%;

  &::placeholder {
    color: #d0c9d6; } }

.newsletter-form__btn {
  background: $accent;
  border: none;
  border-radius: 0 6px 6px 0;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  padding: 11px 16px;
  transition: all .4s ease;
  width: 50%;

  &:hover {
    background: #bd85f1; } }

/* === Section top 10 === */
.best-set {
  padding-bottom: 55px;
  padding-top: 55px;
  @include media-breakpoint-down(md) {
    padding-bottom: 25px;
    padding-top: 25px;
    .owl-dots {
      display: none; } } }

.best-set__desc {
  font-size: 18px;
  margin-bottom: 32px; }

.best-set__list {
  margin-bottom: 0;
  .nav {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .owl-dots {
    display: none; } }

.best-set__list .owl-carousel {
  width: calc(100% - 60px);
  margin: 0 auto; }

.best-set__item {
  background: #fff no-repeat center;
  border-radius: 20px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, .07);
  cursor: pointer;
  height: 246px;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-size: contain;

  .best-set__img {
    height: 246px;
    display: flex; }
  img {
    width: 100%;
    object-fit: contain; }

  &:hover {
    .best-set__name {
      white-space: normal; }
    .best-set__mask {
      transform: translate(0, 20%); } } }

.best-set__mask {
  background: rgba(242, 242, 242, .95);
  bottom: 0;
  height: 166px;
  left: 0;
  box-sizing: border-box;
  padding: 20px 10px;
  position: absolute;
  text-align: center;
  transform: translate(0, 65%);
  transition: transform .5s ease;
  width: 100%; }

.best-set__caption {
  font-family: $accent-font;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 5px; }

.best-set__name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.best-set__btn {
  text-decoration: none;

  &:hover {
    text-decoration: none; }

  &:focus {
    text-decoration: none; } }

.best-set__action {
  text-align: center;
  margin-top: 30px; }

.best-set__action-btn {
  span {
    margin-right: 10px; } }

.best-set__icon-arrow {
  fill: #fff;
  height: 14px;
  width: 14px; }

.stickers-container {
  .container {
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: auto; }
  .sticker-row {
    display: flex;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0; } }

  a {
    white-space: nowrap;
    display: inline-block;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 30px;
    background: #f3f3f3;
    border: 2px solid transparent;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0; }
    &:hover {
      border-color: $accent; } } }

.howto {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  &-item {
    width: 300px;
    @include media-breakpoint-down(md) {
      width: 100%; }
    &__text {
      line-height: 1.5;
      font-size: 18px; }
    &__inner {
      display: flex;
      margin-bottom: 20px; }
    &__no {
      min-width: 50px;
      font-size: 28px;
      font-weight: 700;
      color: $accent;
      line-height: 1;
      margin-top: 6px; } } }

.summary_format {
  .primary-header {
    text-align: left;
    margin-bottom: 16px; }
  .subheader {
    font-size: 22px;
    line-height: 1.4;
    &:last-of-type {
      margin-bottom: 0; } }
  .row {
    padding: 0 15px;
    display: grid;
    grid-template-areas: 'descr image' 'links image';
    grid-template-columns: 1fr 500px;
    grid-gap: 20px;
    align-items: center;
    @include media-breakpoint-down(md) {
      grid-template-areas: 'descr' 'image' 'links';
      grid-template-columns: 1fr; } } }

.summary_format__description {
  grid-area: descr;
  align-self: end; }

.summary_format__app_links {
  grid-area: links;
  align-self: start;
  display: flex;
  flex-wrap: wrap;
  gap: $gutter;
  @include media-breakpoint-down(md) {
    text-align: center;
    justify-content: center; }
  a {
    color: $frame-btn;
    width: 215px;
    padding-left: 0;
    padding-right: 0;
    &:hover {
      background: $frame-btn;
      color: #fff;
      border-color: $frame-btn;
      box-shadow: 0 20px 25px rgba($frame-btn, .2); }
    &:last-of-type {
      margin-right: 0; }
    @include media-breakpoint-down(sm) {
      font-size: 14px;
      margin-right: 0;
      width: 100%; } } }

.summary_format__image {
  grid-area: image;
  text-align: right;
  width: 100%;
  img {
    width: 100%; }
  @include media-breakpoint-down(md) {
    text-align: center;
    img {
      width: 85%; } } }
